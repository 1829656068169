import { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import FetchAPI from "../../../../../api/FetchAPI";
import { timeSpanLast24Hours } from "../../../../../shared-functions/datetime";

export default function TotalAlerts() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalAlerts, setTotalAlerts] = useState(0);

  useEffect(() => {
    const objectToSend = timeSpanLast24Hours();
    FetchAPI.Monitoring.Summary.getStateChanges(objectToSend)
      .then((res) => {
        const count = Array.isArray(res?.data) ? res.data.length : 0;
        setTotalAlerts(count);
      })
      .catch((e) =>
        setError(e?.response?.data?.error?.message || "An error occurred"),
      )
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="number small margin-top-00 ">
        <div className="flex hcenter vcenter font-M">
          <Icon name="spinner" loading />
          <span className="padding-left-half">Loading</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="number small margin-top-00">
        <span className="font-M ">Alerts</span>
        <span className="font-S ">{error}</span>
      </div>
    );
  }

  return (
    <div className="number small margin-top-00">
      <span className="bigger bold">{totalAlerts}</span>
      <span className="smaller">Alert{totalAlerts !== 1 ? "s" : ""}</span>
    </div>
  );
}
