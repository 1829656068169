import React from "react";
import { Segment, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { State } from "../../../../selectors/state";
import TotalAlerts from "./bits/TotalAlerts";
import UptimePercent from "./bits/UptimePercent";

type MonitoringAccess = "full" | "read" | undefined;

const AlertCount = () => {
  const hasMonitoringAccess: MonitoringAccess = useSelector(
    (state: State) => state.login?.userlogin?.privileges?.citymonitor?.type,
  );

  return (
    <div className={`dashboard-widget`}>
      <Segment className="flex">
        <h2>
          Alerts
          <span>(last 24H)</span>
        </h2>
        {hasMonitoringAccess ? (
          <React.Fragment>
            <TotalAlerts />
            <UptimePercent />
          </React.Fragment>
        ) : (
          <div className="number small margin-top-00">
            <span className="smaller">
              <Icon name="warning circle" /> Monitoring is not available!
            </span>
          </div>
        )}
      </Segment>
    </div>
  );
};

export default AlertCount;
