import { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import FetchAPI from "../../../../../api/FetchAPI";
import { timeSpanLast24Hours } from "../../../../../shared-functions/datetime";
import { AxiosResponse } from "axios";
import { valuesOf } from "../../../../../shared-functions/objects";

export default function UptimePercent() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uptime, setUptime] = useState("");

  useEffect(() => {
    const objectToSend = timeSpanLast24Hours();

    FetchAPI.Monitoring.Summary.getUptime(objectToSend)
      .then((response: AxiosResponse) => {
        const data: number[] = valuesOf(response?.data?.data || {}).map(
          (x: any) => x?.totalUptime,
        );

        if (data.length === 0) {
          setUptime("0%");
          return;
        }
        const totalUptime = data.reduce((acc: number, entry) => acc + entry, 0);
        const uptimePercent =
          Math.floor((totalUptime / data.length) * 1000) / 1000;

        setUptime(`${uptimePercent}%`);
      })
      .catch((e) => {
        setError(e?.response?.data?.error?.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="number small margin-top-00 ">
        <div className="flex hcenter vcenter font-M">
          <Icon name="spinner" loading />
          <span className="padding-left-half">Loading</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="number small margin-top-00">
        <span className="font-M ">Uptime</span>
        <span className="font-S ">{error || "Error"}</span>
      </div>
    );
  }

  return (
    <div className="number small margin-top-00">
      <span className="bigger bold">{uptime}</span>
      <span className="smaller">Uptime</span>
    </div>
  );
}
