import { useEffect } from "react";
import PropTypes from "prop-types";

import { normalizeSelectBoxEvent } from "../../../shared-functions/form";

import { createArrayofSequentialNumbers } from "../../../shared-functions/array";

import { convertArrayToSelectOptions } from "../../../app_shared_functions";

import { Grid, Icon, Select, Popup, Checkbox, Label } from "semantic-ui-react";

import { temporaryTimezone } from "../../../app_constants";

const MaintenanceWindow = ({ maintenance, handleChange }) => {
  const hours = convertArrayToSelectOptions(createArrayofSequentialNumbers(24));
  const minutes = convertArrayToSelectOptions(
    createArrayofSequentialNumbers(60),
  );

  const update = (event) => {
    const newMaintenance = { ...maintenance };

    const name = event?.target?.name || event?.name;
    const value = event?.target?.value || event?.value;

    if (name === "starthours") {
      newMaintenance.begin = value + newMaintenance.begin.substr(2);
    }
    if (name === "startminutes") {
      newMaintenance.begin =
        newMaintenance.begin.substr(0, 2) +
        value +
        newMaintenance.begin.substr(4);
    }
    if (name === "endhours") {
      newMaintenance.end = value + newMaintenance.end.substr(2);
    }
    if (name === "endminutes") {
      newMaintenance.end =
        newMaintenance.end.substr(0, 2) + value + newMaintenance.end.substr(4);
    }
    if (name === "machineimageversion") {
      newMaintenance.machineImageVersion = value;
    }
    if (name === "kubernetesversion") {
      newMaintenance.kubernetesVersion = value;
    }

    handleChange({ name: "maintenance", value: newMaintenance });
  };

  const getRandomMaintenanceWindowTime = () => {
    const toMilitary = (h) => (h > 9 ? h : "0" + h);
    const begin = toMilitary(Math.floor(Math.random() * 24));
    const end = toMilitary((begin + 2) % 24);
    return { begin, end };
  };

  useEffect(() => {
    if (!maintenance) {
      const { begin, end } = getRandomMaintenanceWindowTime();

      handleChange({
        name: "maintenance",
        value: {
          machineImageVersion: true,
          kubernetesVersion: true,
          begin: `${begin}0000+0100`,
          end: `${end}0000+0100`,
        },
      });
    }
  }, [handleChange, maintenance]);

  return maintenance ? (
    <Grid.Row className="separator">
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-30"
      >
        <h5>
          Maintenance Window
          <Popup
            trigger={
              <Icon
                name="warning circle"
                className="margin-left"
                color="grey"
              />
            }
            content={
              <p>
                Specify the time window for when the system can perform
                maintenance. This includes upgrades of Kubernetes and Machine
                images for your worker nodes.
                <br />
                The maintenance window defines the period for when the
                maintenance is allowed to <Label>start</Label>. <br />
                If no maintenance window is set, no automatic updates can be
                performed. We recommend that you specify maintenance window to
                keep your cluster up to date, secure and healthy.
              </p>
            }
          />
        </h5>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30"
      >
        <h5>
          Time
          <Popup
            trigger={
              <Icon
                name="warning circle"
                className="margin-left"
                color="grey"
              />
            }
            content={
              <p>
                The update can start any time between the times provided here.
                <br />
                Note: If the time is set as <Label>03:00 - 05:00</Label> , the
                maintenance window can start anytime between 03:00 and 05:00.
              </p>
            }
          />
        </h5>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30"
      >
        <div className="ui dropdown select-box flex vcenter width-100p no-hover justify-evenly padding-top-00 padding-bottom-00 ">
          <Select
            name="starthours"
            className="select-box select-box--compact text-right"
            value={maintenance?.begin?.substr(0, 2)}
            search
            options={hours}
            onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
          />
          <span className="padding-bottom-half">:</span>
          <Select
            name="startminutes"
            className="select-box select-box--compact"
            value={maintenance?.begin?.substr(2, 2)}
            search
            options={minutes}
            onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
          />

          <span className="padding-bottom-half"> - </span>

          <Select
            name="endhours"
            className="select-box select-box--compact text-right"
            value={maintenance?.end?.substr(0, 2)}
            search
            options={hours}
            onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
          />
          <span className="padding-bottom-half">:</span>
          <Select
            name="endminutes"
            className="select-box select-box--compact"
            value={maintenance?.end?.substr(2, 2)}
            search
            options={minutes}
            onChange={(e, d) => update(normalizeSelectBoxEvent(e, d))}
          />
        </div>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30"
      >
        <h5>Time Zone</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30 padding-right-00 hend"
      >
        <Select
          name="timezone"
          className="select-box full"
          value={temporaryTimezone}
          disabled
          options={convertArrayToSelectOptions([temporaryTimezone])}
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter separator separator--simple "
      >
        <h5>Auto update </h5>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20"
      >
        <Checkbox
          toggle
          name="machineimageversion"
          checked={maintenance.machineImageVersion}
          label={"Workers Operating System"}
          onChange={(e, d) => update({ ...d, value: d.checked })}
        />
        <Popup
          trigger={
            <Icon name="warning circle" color="grey" className="margin-top" />
          }
          content={
            <p>
              Update the operating system of the workers
              <br />
              (requires rolling update of all workers, ensure proper pod
              disruption budgets to ensure availability of your workload)
              <br />
            </p>
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20"
      >
        <Checkbox
          toggle
          name="kubernetesversion"
          checked={maintenance.kubernetesVersion}
          label="Kubernetes Patch Version"
          onChange={(e, d) => update({ ...d, value: d.checked })}
        />
        <Popup
          trigger={
            <Icon name="warning circle" color="grey" className="margin-top" />
          }
          content={
            <p>
              Update the control plane of the cluster and the worker components
              <br />
              (control plane, most notably the API server, will be briefly
              unavailable during switch-over)
              <br />
            </p>
          }
        />
      </Grid.Column>
    </Grid.Row>
  ) : (
    ""
  );
};

MaintenanceWindow.propTypes = {
  maintenance: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
  handleChange: PropTypes.func.isRequired,
};

export default MaintenanceWindow;
