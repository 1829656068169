import * as SVGs from "../../ui/svgs/svgs";
import { defaultValues } from "../../app_constants";
import { Icon } from "semantic-ui-react";
import { getSelectItemClassName } from "../../shared-functions/string";

export const getServerOSIcon = (server) => {
  const extractedOS = (
    server?.image?.os ||
    server.os_type ||
    server.os ||
    server?.image?.os_distro ||
    "unknown"
  ).toLowerCase();

  const UnknownIcon = <Icon name="question" className="font-M" />;

  if (extractedOS.startsWith("gardenlinux")) return <SVGs.Gardener />;

  const mapping = {
    centos: <SVGs.Centos />,
    windows: <SVGs.Windows />,
    freebsd: <SVGs.FreeBSD />,
    ubuntu: <SVGs.Ubuntu />,
    suse: <SVGs.Suse />,
    fedora: <SVGs.Fedora />,
    debian: <SVGs.Debian />,
    "fedora-coreos": <SVGs.Coreos />,
    rocky: <SVGs.Rocky />,
    linux: <SVGs.Linux />,
    "system-rescue": <Icon name="life ring" className="font-L" />,
  };

  return mapping[extractedOS] || UnknownIcon;
};

export const getPowerState = (server) => {
  const power_state = server["OS-EXT-STS:power_state"];
  const isOn = power_state === 1 || power_state === 3;
  return isOn;
};

export const calculateLicensePrice = (cores, license, isOn) => {
  const license_cost = {};

  if (!cores || !license || !isOn)
    return {
      cost: 0,
      details: [],
    };

  if (license && Array.isArray(license)) {
    license_cost.cost = 0;
    license_cost.details = [];

    for (var i = 0; i < license.length; i++) {
      if (license[i].price_multiplier === "unit") {
        const cores_used =
          cores < license[i].dependencies?.cores?.minimum
            ? license[i].dependencies?.cores?.minimum
            : cores;
        const units_used = Math.ceil(
          cores_used / (license[i].dependencies?.cores?.unit || 1),
        );
        license_cost.cost = parseFloat(
          parseFloat(license_cost.cost) +
            units_used * (license[i]?.price_per_hour || 0) * 24 * 30,
        ).toFixed(2);
        license_cost.details.push({
          units: units_used,
          item: license[i].item_name,
          price: license[i]?.price_per_hour,
        });
      } else {
        //  Regular license price = price_per_hour * hours
        const price_per_hour = Number(license[i]?.price_per_hour) || 0;
        license_cost.cost = parseFloat(
          parseFloat(license_cost.cost) + parseFloat(price_per_hour * 24 * 30),
        ).toFixed(2);
        license_cost.details.push({
          units: 1,
          item: license[i].item_name,
          price: license[i]?.price_per_hour,
        });
      }
    }
  }

  return license_cost;
};

export const calculateDRPrice = (
  priceList,
  diskSize,
  zoneId,
  disasterRecoverService,
) => {
  if (priceList) {
    return (
      (disasterRecoverService ? diskSize : 0) *
      24 *
      30 *
      priceList.storage[
        Object.keys(priceList.storage).find((x) => Number(x) === Number(zoneId))
      ]
    ).toFixed(2);
  }
  return 0;
};

export const isResizing = (server) =>
  defaultValues.server.resize_status.includes(server["OS-EXT-STS:task_state"]);

export const isResizeWaiting = (server) =>
  (server["OS-EXT-STS:task_state"] === "resize_migrated" ||
    server?.status?.toLowerCase() === "verify_resize" ||
    server?.status?.toLowerCase() === "resized") &&
  server["OS-EXT-STS:task_state"] == null;

/**
 * Checks if server is already using a floating ip or not
 * @param {*} server
 * @returns boolean
 */
export const canAssignFloatingIP = (server) => {
  const addresses = Object.values(server.addresses).reduce(
    (acc, x) => [...acc, ...x],
    [],
  );

  // floating ip can be assigned to servers that are
  //      1. already attached to at least one network
  //      2. and has no floating ip assigned to them yet
  return (
    addresses.length > 0 &&
    addresses.every((entry) => entry["OS-EXT-IPS:type"] !== "floating")
  );
};

/**
 * Checks if a server has a floating ip attached to it
 * @param {*} server
 * @returns boolean
 */
export const hasFIP = (server) =>
  Object.values(server.addresses)
    .reduce((acc, x) => (acc = [...acc, ...x]), [])
    .some((x) => x["OS-EXT-IPS:type"] === "floating");

/**
 * The provided list of addresses in the server object does not include all the data of the floating ips (misses region, project_id and id)
 * sometimes it's needed to include a detailed list of those floating_ips inside the server object
 *      an example: when we want to release the floating_ips after server deletion
 * @param {*} server        object
 * @param {*} floatingIPs   array : this is the list of all of the floating ips taken from redux
 * @returns [array of filtered floating_ips]
 */
export const includeFloatingIPSList = (server, floatingIPs) => {
  return server.addresses
    ? Object.keys(server.addresses)
        .reduce((acc, item) => {
          const obj = server.addresses[item].filter(
            (x) => x["OS-EXT-IPS:type"] === "floating",
          );
          acc = [...acc, ...obj];
          return acc;
        }, [])
        .map((x) => ({
          ...x,
          ...(floatingIPs.find((f) => f.floating_ip_address === x.addr) || {}),
        }))
    : [];
};

/**
 * Using regex to filter out flavors as applicable for boot from volume
 * @param {flavor} x : Flavor Object
 * @returns {Boolean} Boolean
 */
const isBootableFlavor = (x) => /^b.[0-9]{1,3}c[0-9]{1,3}gb/gi.test(x.name);

/**
 * Filter out flavors that have less ram than minimum
 * @param {*}
 * @returns {Boolean} Boolean
 */
const hasEnoughRam = (x, os) => x.ramGB >= (os?.min_ram / 1024 || 0);

/**
 * Sort flavors ascending starting from minimum cores and then minimum ram
 * @param {flavor} a : flavor
 * @param {flavor} b : flavor
 * @returns {[flavors]} : array of flavors sorted
 */
const sortFlavors = (a, b) =>
  a.vcpus < b.vcpus || (a.vcpus === b.vcpus && a.ramGB < b.ramGB) ? -1 : 1;

/**
 * maps flavor to select options (including ram and cores)
 * NOTE: We need to include ram / cores to be sent up, on select change
 * @param {Flavor} x
 * @returns
 */
const mapFlavorToSelectOption = (x) => {
  const text = `${x.vcpus} Core${x.vcpus > 1 ? "s" : ""}, ${x.ramGB} GB Ram (${
    x.name
  })`;
  return {
    key: `${x.vcpus}-${x.ramGB}`,
    value: x.id,
    ram: x.ramGB,
    cores: x.vcpus,
    flavor: x,
    text: text,
    className: getSelectItemClassName(text),
  };
};

/**
 * renders the complete list of flavors to a usable "boot from volume flavors" list for the select options
 * @param {Flavor[]} flavors
 * @param {{ min_ram: number }} os
 * @param {string | undefined} profile
 * @returns
 */
export const processFlavors = (flavors, os, profileFilter) =>
  flavors
    .filter(
      (x) =>
        (profileFilter
          ? Array.isArray(profileFilter)
            ? x.profiles.find((p) => profileFilter.includes(p))
            : x.profiles.includes(profileFilter)
          : isBootableFlavor(x)) && hasEnoughRam(x, os),
    )
    .sort(sortFlavors)
    .map(mapFlavorToSelectOption);

/**
 *  Get the list of ports 
    Filter those associated with the server 
    Extract the security_group_ids
    Map each id to a security_group in the redux list
 * @param {*} server the server object
 * @param {{}} ports the list of ports taken form redux ports.PORTS_LIST
 * @param {{}} security_groups the list of security groups taken form redux securitygroups.SECURITYGROUPS_LIST
 * @returns 
 */
export const filterSecurityGroups = (server, ports, security_groups) => {
  return Object.values(ports)
    .filter(
      (port) =>
        port.region.toLowerCase() === server.region.toLowerCase() &&
        port.project_id === server.project_id &&
        port.device_id === server.id,
    )
    .reduce((acc, port) => (acc = [...acc, ...port.security_groups]), [])
    .map((security_group_id) => security_groups[security_group_id])
    .filter((security_group) => security_group)
    .filter(
      (security_group, index, array) =>
        index === array.findIndex((next) => next.id === security_group.id),
    );
};

export const filterAttachedVolumes = (volumes, server) =>
  Object.values(volumes.VOLUMES_LIST || {}).filter(
    (x) => x.attachments.length > 0 && x.attachments[0].server_id === server.id,
  );

export const filterSnapshots = (snapshots, server) =>
  Object.values(snapshots.SERVER_SNAPSHOTS_LIST || {})
    .filter((sn) => sn.instance_uuid === server.id)
    .map((x) => ({
      ...x,
      size: `${Math.round(x.size / 1024 / 1024 / 1024)} GB`,
    }));
