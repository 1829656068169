import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Popup, Icon } from "semantic-ui-react";

export type DisasterRecoveryProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  supportedStorageClass: boolean;
};

const Description = ({
  disabled,
  supportedStorageClass,
}: Pick<DisasterRecoveryProps, "disabled" | "supportedStorageClass">) => {
  const { t } = useTranslation();

  if (disabled)
    return (
      <Grid.Column textAlign="left" width={16} className="padding-top-20">
        <p className="italic">
          Disaster recovery is not available for this profile.
        </p>
      </Grid.Column>
    );

  if (!supportedStorageClass)
    return (
      <Grid.Column textAlign="left" width={16} className="padding-top-20">
        <p className="italic">
          {t("volumes.storage_classes.dr_not_supported")}
        </p>
      </Grid.Column>
    );

  return null;
};

const DisasterRecovery = ({
  disabled,
  value,
  onChange,
  supportedStorageClass,
}: DisasterRecoveryProps) => {
  const handleChange = useCallback(() => onChange(!value), [onChange, value]);

  const label = supportedStorageClass
    ? value
      ? "Enabled"
      : "Disabled"
    : "Not supported";

  const checked = supportedStorageClass ? value : false;

  return (
    <Grid.Row className="separator padding-top-00">
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vbottom height-formitem"
      >
        <Popup
          trigger={
            <h5>
              Disaster recovery
              <Icon
                name="exclamation circle"
                color="grey"
                className="margin-left-10"
              />
            </h5>
          }
        >
          The disaster recovery service is a form of backup where complete
          restore of a server takes minutes.
          <br />
          If enabled, daily snapshots of a server are taken.
          <br />
          Rollback to a previous snapshot can easily be done when needed.
        </Popup>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter padding-top-half height-formitem"
      >
        <Checkbox
          toggle
          disabled={disabled || !supportedStorageClass}
          checked={checked}
          label={label}
          onChange={handleChange}
        />
      </Grid.Column>
      <Description
        disabled={disabled}
        supportedStorageClass={supportedStorageClass}
      />
    </Grid.Row>
  );
};

export default DisasterRecovery;
