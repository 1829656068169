import config from "../../../config";
import getFetch from "../../getFetch";

type ProjectRegionAndFlavor = {
  regionId: string;
  projectId: string;
  flavorId: string;
};

type CostEstimateQueryParams = {
  imageId?: string;
  drServiceEnabled?: boolean;
  withFloatingIp?: boolean;
  volumeSize?: number;
  interval?: "hourly" | "daily" | "monthly" | "yearly";
  storageClass?: string | false;
};

type GetCostEstimateProps = ProjectRegionAndFlavor & CostEstimateQueryParams;

type CostEstimateItem = {
  price: number;
  amount: number;
  unit: string;
};

export type GetCostEstimateResponse = {
  currency: string;
  total: number;
  flavorName: string;
  interval: string;
  itemsCost: {
    cpu: CostEstimateItem;
    gpu: CostEstimateItem;
    disk: CostEstimateItem;
    volume: CostEstimateItem;
    ram: CostEstimateItem;
    image: CostEstimateItem;
    dr_service: CostEstimateItem;
    floating_ip: CostEstimateItem;
  };
  details: {
    image: {
      imageId: string;
      total: number;
      items: {
        name: string;
        price: number;
        units: number;
      }[];
    };
  };
};

function getCostEstimateQueryString(params: CostEstimateQueryParams): string {
  const args: [string, string][] = [];

  if (params.imageId) {
    args.push(["image_id", encodeURIComponent(params.imageId)]);
  }

  if (params.drServiceEnabled) {
    args.push(["dr_service_enabled", "true"]);
  }

  if (params.withFloatingIp) {
    args.push(["with_floating_ip", "true"]);
  }

  if (params.volumeSize !== undefined) {
    args.push(["volume_size", String(Math.round(params.volumeSize))]);
  }

  if (params.storageClass) {
    args.push(["volume_type", encodeURIComponent(params.storageClass)]);
  }

  if (params.interval) {
    args.push(["interval", params.interval]);
  }

  if (args.length) {
    return "?" + args.map((pair) => pair.join("=")).join("&");
  }

  return "";
}

const CostEstimate = {
  GetCostEstimate: function (props: GetCostEstimateProps) {
    const { regionId, projectId, flavorId, ...queryParams } = props;

    const queryString = getCostEstimateQueryString(queryParams);

    return getFetch<GetCostEstimateResponse>({
      url: `${config.api_url}/compute/v1/cost_estimate/${regionId}/${projectId}/${flavorId}${queryString}`,
      type: "get",
    });
  },
};

export default CostEstimate;
