import { useCallback, useEffect, useState } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { Loader, Popup, Icon, Tab } from "semantic-ui-react";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { createTableHeaderObject } from "../../../../app_shared_functions";
import monitoringValues from "../../monitoring-values";
import { toast } from "react-toastify";
import { secondsToTimeChunks } from "../../../../shared-functions/datetime";

import AlertStatus from "./bits/AlertStatus";

const Alerts = ({ schedule }) => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const { limit } = monitoringValues.schedules.alert;

  // Will compute the duration between two dates
  // fromDate: date string "2022-08-09 11:41:00"
  // toDate: date string "2022-08-09 11:41:00"
  // isFirstRow: boolean
  const calculateDuration = (fromDate, toDate, isFirstRow) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const diff = (to.getTime() - from.getTime()) / 1000;

    const duration = secondsToTimeChunks(diff);

    return `${duration} ${isFirstRow ? " and counting..." : ""}`;
  };

  // This function will act on the first row of the data to be displayed and
  // if we this is the beginning of the data (no rows before) we will return Now as pervious row
  // if this is the first row of received data, but we have rows beforehand (we are in page 2-3...) we will return the last row of the data we already have
  // otherwise we are not in the first row, the pervious row is already there in the array
  const getPerviousRowDate = useCallback(
    (i) => {
      if (i === 0 && offset === 0) {
        const now = new Date();
        return `${now.getUTCFullYear()}-${
          now.getUTCMonth() + 1
        }-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`;
      } else if (i === 0 && offset !== 0) {
        return alerts[offset - 1].date;
      }

      return null;
    },
    [alerts, offset],
  );

  const getData = useCallback(
    (offset = 0) => {
      setLoading(true);

      if (offset === alerts.length) {
        FetchAPI.Monitoring.Schedule.listStateChanges({
          schedule,
          limit,
          offset,
        })
          .then((res) => {
            const { data } = res;
            if (data.length === 0 && offset === 0) {
              // Case when there is no alert found for this schedule
              toast.warn("No alerts found for this schedule.");
            } else if (data.length === 0 && offset > 0) {
              // This is the case when there is no more data returned from API
              // We save rows length to be used in Next button later
              setOffset(offset - limit);
              setTotalRows(alerts.length);
              toast.warn("Sorry! You don't have alerts beyond this.");
            } else {
              const mappedData = data.map((x, i) => ({
                ...x,
                duration: calculateDuration(
                  x.date,
                  getPerviousRowDate(i) || data[i - 1].date,
                  i === 0 && offset === 0,
                ),
                __color__: { status: x.state },
                status: <AlertStatus status={x.state} />,
              }));
              setAlerts([...alerts, ...mappedData]);
            }
          })
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    },
    [getPerviousRowDate, limit, schedule, alerts],
  );

  const nextPage = () => {
    setOffset(offset + limit);
    getData(offset + limit);
  };

  const perviousPage = () => {
    setOffset(offset - limit);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const ViewableAlerts = alerts.slice(offset, offset + limit);

  const columns = createTableHeaderObject(
    "__Hidden__",
    monitoringValues.schedules.alert.columns,
  );

  return (
    <Tab.Pane className="padding-right-30 padding-left-30">
      <div className={`${loading ? "cursor_not-allowed" : ""}`}>
        {loading && (
          <div className="loader-wrapper double-height">
            <Loader>Loading</Loader>
          </div>
        )}

        {ViewableAlerts.length > 0 && (
          <TableWrapper data={ViewableAlerts} columns={columns} />
        )}

        {!loading && ViewableAlerts.length === 0 && (
          <div className="loader-wrapper double-height flex vcenter">
            <Icon
              name="warning circle"
              size="large"
              className="margin-left-half margin-right-half"
            />
            No Alerts triggered for this schedule yet!
          </div>
        )}

        {ViewableAlerts.length ? (
          <div className="padding-bottom-30">
            {ViewableAlerts?.length === limit &&
            !loading &&
            offset + limit !== totalRows ? (
              <button
                className={`float-right button button--orange button--icon__right`}
                onClick={nextPage}
              >
                <Icon name="chevron right circle" />
                <span className="color-white">Next</span>
              </button>
            ) : (
              <Popup
                trigger={
                  <button className="float-right button button--orange button--icon__right button--disabled">
                    <Icon name="chevron right circle" />
                    <span className="color-white">Next</span>
                  </button>
                }
                on="hover"
                content="No more alerts to load"
              />
            )}

            {offset > 0 && !loading ? (
              <button
                className={`float-left button button--orange button--icon__left`}
                onClick={perviousPage}
              >
                <Icon name="chevron left circle" />
                <span className="color-white">Back</span>
              </button>
            ) : (
              <button className="float-left button button--orange button--icon__left button--disabled">
                <Icon name="chevron left circle" />
                <span className="color-white">Back</span>
              </button>
            )}
          </div>
        ) : null}
      </div>
    </Tab.Pane>
  );
};

export default Alerts;
