import { useMemo } from "react";
import {
  DropdownProps,
  Grid,
  Icon,
  Input,
  Loader,
  Popup,
  Select,
} from "semantic-ui-react";
import { getSelectItemClassName } from "../../../../shared-functions/string";

export type StorageClassType = {
  description: string;
  disasterRecoverAvailable: boolean;
  isPublic: boolean;
  name: string;
  price: {
    costItem: string;
    currency: {
      code: string;
      id: number;
      name: string;
    };
    regions: {
      costPerHour: number;
      costPerMonth: number;
      region: string;
      zoneId: string;
    }[];
  };
};

type StorageClassesProps = {
  selectedClass: StorageClassType;
  classes: {
    data: StorageClassType[];
    isLoading: boolean;
    isError: boolean;
  };
  updateVolumeClass: (type: StorageClassType) => void;
};

export default function StorageClasses({
  classes,
  selectedClass,
  updateVolumeClass,
}: StorageClassesProps) {
  const onChange = (
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
  ) => {
    const selected = classes?.data?.find((item) => item.name === data.value);
    if (selected) {
      updateVolumeClass(selected);
    }
  };

  const options = useMemo(() => {
    if (Array.isArray(classes.data))
      return classes.data.map((classItem, i) => ({
        key: i,
        value: classItem.name,
        text: classItem.name,
        className: getSelectItemClassName(classItem.name),
      }));

    return [];
  }, [classes]);

  return (
    <Grid.Row className="padding-top-20 separator padding-bottom-30">
      <Grid.Column textAlign="left" width={8} className="flex vcenter">
        <h5>
          Storage classes (types)
          <Popup
            trigger={
              <Icon
                name="warning circle"
                className="padding-left-half"
                size="small"
              />
            }
            content={
              <p>
                Storage classes offer different options for performance,
                encryption, and cost.
                <br /> Choose a storage class to see the detailed description.
              </p>
            }
          />
        </h5>
      </Grid.Column>
      <Grid.Column textAlign="left" width={8} className="flex vcenter">
        {classes.isLoading && (
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner ">
              Fetching Network list...
            </Loader>
          </div>
        )}
        {classes.isError && <Input disabled value="Not Found!" />}
        {classes.data && (
          <Select
            icon="chevron circle down"
            className="select-box full"
            options={options}
            onChange={onChange}
            value={selectedClass.name}
          />
        )}
      </Grid.Column>
      {classes.isError && (
        <Grid.Column
          textAlign="left"
          width={16}
          className="margin-top-30 flex vcenter"
        >
          <p>
            <Icon
              name="exclamation circle"
              className="margin-right-half color-red"
            />
            {classes.isError}
          </p>
        </Grid.Column>
      )}
      {selectedClass && (
        <Grid.Column
          textAlign="left"
          width={16}
          className="margin-top-30 flex vcenter"
        >
          <p>
            {selectedClass.description || (
              <span className="italic">
                There is currently no description available for this storage
                type.
              </span>
            )}
          </p>
        </Grid.Column>
      )}
    </Grid.Row>
  );
}
