import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FetchAPI from "../../../../api/FetchAPI";
import Invoice from "../../../../components/shared/invoice/Invoice";
import { getCurrentProjectID } from "../../../../app_shared_functions";
import { GetCostEstimateResponse } from "../../../../api/resources/Compute/CostEstimate";
import { toast } from "react-toastify";
import { keysOf } from "../../../../shared-functions/objects";

type MonthlyPriceProps = {
  regionId: string | undefined;
  flavorId: string | undefined;
  floatingIp?: boolean;
  volumeSize?: number;
  disasterRecovery?: boolean;
  imageId: string | undefined;
  rounded?: boolean;
  status?: string;
  storageClass: string | false;
};

const MonthlyPrice = ({
  disasterRecovery,
  regionId,
  flavorId,
  floatingIp,
  volumeSize,
  imageId,
  status = "Waiting",
  rounded,
  storageClass,
}: MonthlyPriceProps) => {
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState<GetCostEstimateResponse>();
  const projects = useSelector((state) => (state as any).projects as unknown);
  const projectId = useMemo(
    () => regionId && getCurrentProjectID(projects, regionId),
    [projects, regionId],
  );

  useEffect(() => {
    if (regionId && projectId && flavorId) {
      setLoading(true);

      FetchAPI.Compute.CostEstimate.GetCostEstimate({
        projectId,
        flavorId,
        regionId,
        imageId,
        drServiceEnabled: disasterRecovery,
        withFloatingIp: floatingIp,
        volumeSize: volumeSize,
        storageClass: storageClass || false,
      })
        .then((res) => {
          setCost(res.data);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong fetching cost estimate!");

          setCost(undefined);
        })
        .finally(() => setLoading(false));
    } else {
      setCost(undefined);
    }
  }, [
    projectId,
    flavorId,
    disasterRecovery,
    floatingIp,
    volumeSize,
    regionId,
    imageId,
    storageClass,
  ]);

  const invoiceItems = useMemo(
    () => (cost ? mapCostEstimateToInvoiceItems(cost) : []),
    [cost],
  );

  return (
    <div className="invoice_wrapper">
      <Invoice
        status={status}
        currency={cost?.currency}
        invoice_Items={invoiceItems}
        interval={cost?.interval ?? "monthly"}
        rounded={rounded}
        loading={loading}
      />
    </div>
  );
};

export default MonthlyPrice;

function mapItemCostNameToDisplayName(key: string): string {
  switch (key) {
    case "cpu":
    case "gpu":
    case "ram":
      return key.toUpperCase();
    case "dr_service":
      return "Disaster recovery";
    case "floating_ip":
      return "Floating IP";
    default:
      return `${key[0].toUpperCase()}${key.slice(1)}`;
  }
}

function mapCostEstimateToInvoiceItems(
  cost: GetCostEstimateResponse,
): { name: string; price: string; count: string; unit: string }[] {
  return keysOf(cost.itemsCost).map((key) => {
    const item = cost.itemsCost[key];

    return {
      name: mapItemCostNameToDisplayName(key),
      price: item.price.toFixed(2),
      count: item.amount.toString(),
      unit: item.unit,
    };
  });
}
